import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import PaperStack from "../../../../../components/paper-stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Poem from "../../../../../components/poem"
import TextPicker from "../../../../../components/text-picker"

const pickerSet = [
  "😭",
  "😱",
  "🥰",
  "🥶",
  "😳",
  "😚",
  "😒",
  "😡",
  "🤔",
  "🤐",
  "😪",
  "😫",
  "🤩",
  "🤨",
  "😤",
  "😩",
  "😨",
  "🥺",
  "🧐",
  "🤪",
  "🥴",
  "🤮",
  "🤧",
  "😇",
  "🥵",
  "😧",
  "😮",
  "🤨",
  "🤭",
  "🤫",
  "😏",
  "🤗",
  "😴",
  "😔",
  "😞",
  "🥱",
  "🤒",
  "☝️",
  "👍",
  "👎",
  "👋",
  "✌️",
]

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "reiseerinnerung",
          chapterId: "01-suedfrankreich",
          taskId: "gedicht-in-emojis",
        })
        navigate(
          "/kurse/reiseerinnerung/01-suedfrankreich/gedicht-in-emojis/bildergeschichte"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Das Gedicht in Emojis" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Gedicht in Emojis
        </Heading>
        <Paragraph>
          Das Gedicht ›Andenken‹ entsteht erst einige Zeit später, im Jahr 1803.
          In der Zwischenzeit erreichte Hölderlin im Sommer 1802 die Nachricht
          über den Tod seiner Geliebten, Susette Gontard, der Ehefrau seines
          Frankfurter Arbeitgebers. Sein Gemütszustand verschlechterte sich. Die
          Hoffnungen auf einen Neuanfang wurden immer aussichtsloser.
        </Paragraph>
        <Paragraph>
          Sieh dir noch einmal das Gedicht an und achte auf die Stimmungen im
          Gedicht. Welche Haltung hat das lyrische Ich zu den im Gedicht
          aufgerufenen Reiseerinnerungen? Welche Stimmung wird über die
          Beschreibungen und Bilder des Gedichts transportiert?
        </Paragraph>

        <TaskSidebar
          main={
            <PaperStack>
              <Stack>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Der Nordost wehet, </p>
                      <p>Der liebste unter den Winden </p>
                      <p>Mir, weil er feurigen Geist </p>
                      <p>Und gute Fahrt verheißet den Schiffern. </p>
                      <p>Geh aber nun und grüße </p>
                      <p>Die schöne Garonne, </p>
                      <p>Und die Gärten von Bourdeaux </p>
                      <p>Dort, wo am scharfen Ufer </p>
                      <p>Hingehet der Steg und in den Strom </p>
                      <p>Tief fällt der Bach, darüber aber </p>
                      <p>Hinschauet ein edel Paar </p>
                      <p>Von Eichen und Silberpappeln;</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-01" set={pickerSet} />
                      <TextPicker id="q-02" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Noch denket das mir wohl und wie </p>
                      <p>Die breiten Gipfel neiget </p>
                      <p>Der Ulmwald , über die Mühl', </p>
                      <p>Im Hofe aber wächset ein Feigenbaum. </p>
                      <p>An Feiertagen gehn </p>
                      <p>Die braunen Frauen daselbst </p>
                      <p>Auf seidnen Boden, </p>
                      <p>Zur Märzenzeit, </p>
                      <p>Wenn gleich ist Nacht und Tag, </p>
                      <p>Und über langsamen Stegen, </p>
                      <p>Von goldenen Träumen schwer. </p>
                      <p>Einwiegende Lüfte ziehen.</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-03" set={pickerSet} />
                      <TextPicker id="q-04" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Es reiche aber, </p>
                      <p> Des dunkeln Lichtes voll, </p>
                      <p> Mir einer den duftenden Becher,</p>
                      <p> Damit ich ruhen möge; denn süß </p>
                      <p> Wär' unter Schatten der Schlummer. </p>
                      <p> Nicht ist es gut, </p>
                      <p> Seellos von sterblichen </p>
                      <p>Gedanken zu seyn. Doch gut </p>
                      <p> Ist ein Gespräch und zu sagen </p>
                      <p> Des Herzens Meinung, zu hören viel </p>
                      <p> Von Tagen der Lieb’, </p>
                      <p> Und Thaten, welche geschehen.</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-05" set={pickerSet} />
                      <TextPicker id="q-06" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Wo aber sind die Freunde? Bellarmin </p>
                      <p> Mit dem Gefährten? Mancher </p>
                      <p> Trägt Scheue, an die Quelle zu gehn; </p>
                      <p> Es beginnet nemlich der Reichtum </p>
                      <p> Im Meere. Sie, </p>
                      <p> Wie Mahler, bringen zusammen </p>
                      <p> Das Schöne der Erd' und verschmähn </p>
                      <p> Den geflügelten Krieg nicht, und </p>
                      <p> Zu wohnen einsam, jahrlang, unter </p>
                      <p>
                        {" "}
                        Dem entlaubten Mast, wo nicht die Nacht durchglänzen{" "}
                      </p>
                      <p> Die Feiertage der Stadt, </p>
                      <p> Und Saitenspiel und eingeborener Tanz nicht.</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-07" set={pickerSet} />
                      <TextPicker id="q-08" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p> Nun aber sind zu Indiern </p>
                      <p> Die Männer gegangen, </p>
                      <p> Dort an der luftigen Spiz’ </p>
                      <p> An Traubenbergen, wo herab </p>
                      <p> Die Dordogne kommt.</p>
                      <p> Und zusammen mit der prächt'gen </p>
                      <p>Garonne meerbreit </p>
                      <p> Ausgehet der Strom. Es nehmet aber</p>
                      <p> Und giebt Gedächtniß die See, </p>
                      <p> Und die Lieb’ auch heftet fleißig die Augen, </p>
                      <p> Was bleibet aber, stiften die Dichter.</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-09" set={pickerSet} />
                      <TextPicker id="q-10" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
              </Stack>
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Finde passende Emojis für die Stimmungen im Gedicht. Füge hinter
              jeder Strophe bis zu drei Emojis ein, indem du auf die großen
              Fragezeichen klickst.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
